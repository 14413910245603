import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

const StyledExtendedHeader = styled(Box)(({ theme }) => ({
  background: theme.palette.common.white,
  margin: '12px',
  width: '100%',
  padding: '16px 24px 13px',
  borderRadius: '16px',

  display: 'flex',
  justifyContent: 'space-between',
}));

const headerTop = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  gap: '16px',
};

const rightSide = {
  display: 'flex',
  alignItems: 'flex-start',
  gap: '40px',
};

const extendedNavList = {
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  marginTop: '16px',
  gap: '12px',
};

export { StyledExtendedHeader, headerTop, rightSide, extendedNavList };
