import { TableCell, TableRow } from '@mui/material';
import { tableCellStyle } from './style';

type Props = {
  item: any;
};

export const TableFooterRow = ({ item }: Props) => (
  <TableRow data-sm="table-footer-row">
    <TableCell sx={tableCellStyle}>
      <TableRow>{item?.title || ''}</TableRow>
    </TableCell>
    <TableCell sx={tableCellStyle}>
      <TableRow>{item?.siteNameValue || ''}</TableRow>
    </TableCell>
    <TableCell sx={tableCellStyle}>
      <TableRow>{item?.siteTypeValue || ''}</TableRow>
    </TableCell>
    <TableCell sx={tableCellStyle}>
      <TableRow>{item?.assetNameValue || ''}</TableRow>
    </TableCell>
    <TableCell sx={tableCellStyle}>
      <TableRow>{item?.assetTypeValue || ''}</TableRow>
    </TableCell>
    <TableCell sx={tableCellStyle}>
      <TableRow>{item?.assetPropertyValue || ''}</TableRow>
    </TableCell>
  </TableRow>
);
