import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Container, Box } from '@mui/material';
import { FormProvider } from 'src/components/minimals/form';
import { ReportPreview, AssetFormHeaderBuilder } from '../components';
import { useResponsive } from 'src/hooks';

type FormDataType = {
  [key: string]: string;
};

export const AssetReportsPage = () => {
  const isTablet = useResponsive('down', 'lg');
  const [formValues] = useState<FormDataType>({
    reportType: '',
    timeZone: '',
  });
  const methods = useForm<FormDataType>({
    defaultValues: formValues,
  });
  return (
    <Container maxWidth={false} disableGutters data-testId="reports-page">
      <FormProvider methods={methods}>
        <AssetFormHeaderBuilder isTablet={isTablet} />
        <Box
          sx={{ display: 'flex', marginTop: '24px', flexDirection: isTablet ? 'column' : 'row' }}
        >
          <Box>Side bar</Box>
          <ReportPreview title="Report name - Apr 24th 2024. - May 24th 2024." />
        </Box>
      </FormProvider>
    </Container>
  );
};

export default AssetReportsPage;
