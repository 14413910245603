import React from 'react';
import { Box, useTheme } from '@mui/material';
import { IconsTypesProps } from 'src/assets/icons/types';
import { StyledTitle, StledExtendedNavItem, iconBox } from './style';

type Props = {
  title: string;
  url: string;
  icon: React.FunctionComponent<IconsTypesProps>;
  greyColor: string;
  activeItem: string;
  onSetActiveItemHandler: (newActiveItem: string) => void;
};

export const ExtendedNavItem = ({
  icon,
  title,
  greyColor,
  url,
  activeItem,
  onSetActiveItemHandler,
}: Props) => {
  const theme = useTheme();
  const iconComponent = React.createElement(icon, { fill: greyColor });
  const isActive = activeItem === title;

  const onClickHandler = () => {
    onSetActiveItemHandler(title);
  };
  return (
    <StledExtendedNavItem theme={theme} isActive={isActive} onClick={onClickHandler}>
      <Box sx={iconBox}>{iconComponent}</Box>
      <StyledTitle theme={theme} isActive={isActive}>
        {title}
      </StyledTitle>
    </StledExtendedNavItem>
  );
};
