import { TableFooter } from '@mui/material';
import { TableFooterRow } from './components';

type Props = {
  data: any;
};

export const TableFooterComponent = ({ data }: Props) => (
  <TableFooter>
    {data.map((item: any) => (
      <TableFooterRow key={item.id} item={item} />
    ))}
  </TableFooter>
);
