import { Table, TableBody, TableContainer, Card, useTheme } from '@mui/material';
import { TableHeadCustom, TableRowsSkeleton, MemoizedScrollbar } from 'src/components';
import { AssetReportTableRowItem } from '../asset-report-table-row-item';
import { ReportPreviewHeader } from '../report-preview-header';
import { TableFooterComponent } from '../table-footer-component';
import { DATA, TABLE_FOOTER_DATA, ASSET_REPORT_PREVIEW_TABLE_HEAD } from './config';

type Props = {
  title: string;
};

export const ReportPreview = ({ title }: Props) => {
  const theme = useTheme();
  const lightGreyColor = theme.palette.grey[200];

  return (
    <Card sx={{ width: '100%' }}>
      <ReportPreviewHeader title={title} />
      <TableContainer>
        <MemoizedScrollbar>
          <Table size="medium" data-testId="alarms-table">
            <TableHeadCustom
              tableCellStyle={{ background: `${lightGreyColor} !important` }}
              headLabel={ASSET_REPORT_PREVIEW_TABLE_HEAD}
            />

            <TableBody data-testId="table-alarms-content">
              {DATA.loaded ? (
                DATA.values?.map((row: any) => <AssetReportTableRowItem key={row.id} row={row} />)
              ) : (
                <TableRowsSkeleton rowsNum={8} cellsNum={8} />
              )}
            </TableBody>
            <TableFooterComponent data={TABLE_FOOTER_DATA} />
          </Table>
        </MemoizedScrollbar>
      </TableContainer>
    </Card>
  );
};
