import { Box, Button, TextField, useTheme } from '@mui/material';
import { RHFTextField, RHFAutocomplete } from 'src/components/minimals/form';
import { StyledAssetFormHeaderBuilder, btnsBox, filedsBox, inputFiled } from './style';

type Props = {
  isTablet: boolean;
};

export const AssetFormHeaderBuilder = ({ isTablet }: Props) => {
  const onSaveHandler = () => {};
  const theme = useTheme();
  return (
    <StyledAssetFormHeaderBuilder theme={theme} isTablet={isTablet}>
      <Box sx={filedsBox}>
        <RHFTextField
          sx={inputFiled}
          name="reportType"
          testId="reportType"
          size="small"
          label="Report Type"
        />
        <RHFAutocomplete
          sx={inputFiled}
          name="timeZone"
          options={['test', 'test 1']}
          size="small"
          renderInput={(params) => <TextField {...params} fullWidth label={'Time Zone'} />}
        />
      </Box>
      <Box sx={btnsBox}>
        <Button size="medium" variant="outlined">
          Undo Changes
        </Button>
        <Button size="medium" variant="contained" onClick={onSaveHandler}>
          Save Template
        </Button>
      </Box>
    </StyledAssetFormHeaderBuilder>
  );
};
