import { CalendarScheduleIcon, EditIcon, ListIcon } from 'src/assets';

export const ASSET_REPORT_NAV_ITEMS = [
  {
    title: 'Editor',
    icon: EditIcon,
    url: '',
  },
  {
    title: 'History',
    icon: ListIcon,
    url: '',
  },
  {
    title: 'Schedule',
    icon: CalendarScheduleIcon,
    url: '',
  },
];
