import { TableRow, TableCell, Typography, useTheme } from '@mui/material';

type Props = {
  row: any;
};

export const AssetReportTableRowItem = ({ row }: Props) => {
  const { date, siteName, assetName, assetType, assetProperty, siteType } = row;
  const theme = useTheme();
  return (
    <TableRow hover data-testId="table-row" sx={{ background: theme.palette.common.white }}>
      <TableCell data-testId="date-row">
        <Typography variant="subtitle2">{date || '-'}</Typography>
      </TableCell>
      <TableCell data-testId="site-name-row">
        <Typography variant="subtitle2">{siteName || '-'}</Typography>
      </TableCell>
      <TableCell data-testId="site-type-row">
        <Typography variant="subtitle2">{siteType || '-'}</Typography>
      </TableCell>
      <TableCell data-testId="asset-name-row">
        <Typography variant="subtitle2">{assetName || '-'}</Typography>
      </TableCell>
      <TableCell data-testId="asset-type-row">
        <Typography variant="subtitle2">{assetType}</Typography>
      </TableCell>
      <TableCell data-testId="asset-type-row">
        <Typography variant="subtitle2">{assetProperty}</Typography>
      </TableCell>
    </TableRow>
  );
};
