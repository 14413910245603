export const ASSET_REPORT_PREVIEW_TABLE_HEAD = [
  {
    id: 'date',
    label: 'Date',
    align: 'left',
    testId: 'asset-date-header',
  },
  {
    id: 'siteName',
    label: 'Site Name',
    align: 'left',
    testId: 'asset-site-name-header',
  },
  {
    id: 'siteType',
    label: 'Site Type',
    align: 'left',
    testId: 'asset-site-type-header',
  },
  {
    id: 'assetName',
    label: 'Asset Name',
    align: 'left',
    testId: 'asset-asset-name-header',
  },
  {
    id: 'assetType',
    label: 'Asset Type',
    align: 'left',
    testId: 'asset-asset-type-header',
  },
  {
    id: 'assetProperty',
    label: 'Asset Property',
    subLabel: 'Average (L/min)',
    align: 'left',
    testId: 'asset-asset-property-header',
  },
];

//Temporary data
export const DATA = {
  loaded: true,
  values: [
    {
      id: 1,
      date: 'Apr 24th 2024.',
      siteName: 'Test site name',
      siteType: 'some site type',
      assetName: 'Pump 2',
      assetType: 'Pump',
      assetProperty: 'Pump',
    },
    {
      id: 1,
      date: 'Apr 24th 2024.',
      siteName: 'Test site name',
      siteType: 'some site type',
      assetName: 'Pump 1',
      assetType: 'Pump',
      assetProperty: 'Pump',
    },
  ],
};

export const TABLE_FOOTER_DATA = [
  {
    id: 1,
    title: 'Total',
    siteNameValue: '100',
    siteTypeValue: '100',
    assetNameValue: '-',
    assetTypeValue: '100',
    assetPropertyValue: '50',
  },
  {
    id: 2,
    title: 'Min',
    siteNameValue: '100',
    siteTypeValue: '100',
    assetNameValue: '-',
    assetTypeValue: '100',
    assetPropertyValue: '50',
  },
  {
    id: 3,
    title: 'Max',
    siteNameValue: '100',
    siteTypeValue: '100',
    assetNameValue: '-',
    assetTypeValue: '100',
    assetPropertyValue: '50',
  },
  {
    id: 4,
    title: 'Average',
    siteNameValue: '100',
    siteTypeValue: '100',
    assetNameValue: '-',
    assetTypeValue: '100',
    assetPropertyValue: '50',
  },
];
