import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';

const StyledTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[800],
  fontWeight: '700',
  fontSize: '24px',
}));

const editIconBox = {
  cursor: 'pointer',
  marginTop: '5px',
  marginLeft: '12px',
};

export { StyledTitle, editIconBox };
