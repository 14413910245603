import { Box } from '@mui/material';
import { StyledTitle, editIconBox } from './style';
import { EditIcon } from 'src/assets';

type Props = {
  title: string;
  iconSize: number;
  greyColor: string;
  toggleEditHandler: () => void;
};

export const DisplayTitleBox = ({ title, iconSize, greyColor, toggleEditHandler }: Props) => (
  <Box sx={{ display: 'flex', alignItems: 'center' }}>
    <StyledTitle>{title}</StyledTitle>
    <Box sx={editIconBox} onClick={toggleEditHandler} data-testid="edit-icon-box">
      <EditIcon width={iconSize} height={iconSize} fill={greyColor} />
    </Box>
  </Box>
);
