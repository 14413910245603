import { styled, Theme } from '@mui/material/styles';
import { Box } from '@mui/material';

type StyledAssetFormHeaderBuilderTypes = {
  isTablet: boolean;
  theme: Theme;
};

const StyledAssetFormHeaderBuilder = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isTablet',
})(({ theme, isTablet }: StyledAssetFormHeaderBuilderTypes) => ({
  background: theme.palette.common.white,
  width: '100%',
  padding: '24px 20px',
  borderRadius: '16px',
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: isTablet ? 'column' : 'row',
  gap: isTablet ? '10px' : '0px',
}));

const btnsBox = {
  display: 'flex',
  alignItems: 'center',
  gap: '12px',
};

const filedsBox = {
  display: 'flex',
  alignItems: 'center',
  gap: '16px',
};

const inputFiled = {
  width: '280px',
  maxWidth: '100%',
};

export { StyledAssetFormHeaderBuilder, btnsBox, filedsBox, inputFiled };
