import React, { useState } from 'react';
import { Box, IconButton, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';
import { ArrowBackBox, SwitchAccountBtn, UserPopoverBtn } from 'src/components';
import { ListIcon } from 'src/assets';
import { TENANT_SWITCH_PATH } from 'src/routes/config';
import { StyledExtendedHeader, headerTop, rightSide, extendedNavList } from './style';
import { ExtendedNavItemTypes } from './types';
import { useMapFilters } from 'src/contexts/siteMapFilterContext';
import { DisplayTitleBox, EditTitleBox, ExtendedNavItem } from './components';

type Props = {
  extendedNavItems?: ExtendedNavItemTypes[];
  isMobile: boolean;
  showButton?: boolean;
  isTransparent: boolean;
};

export const ExtendedHeader = ({
  extendedNavItems,
  isMobile,
  showButton = false,
  isTransparent,
}: Props) => {
  const theme = useTheme();
  const iconSize = 22;
  const greyColor = theme.palette.grey[500];
  const greenColor = theme.palette.success.main;

  const defaultActiveItem = extendedNavItems ? extendedNavItems[0].title : '';

  const [activeItem, setActiveItem] = useState<string>(defaultActiveItem);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [title, setTitle] = useState<string>('Test title');

  const onSetActiveItemHandler = (newActiveItem: string) => {
    setActiveItem(newActiveItem);
  };

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(event.target.value);
  };

  const toggleEditHandler = () => {
    setIsEditing((prevState) => !prevState);
  };

  const { updateMapFilters } = useMapFilters();

  const handleAccountSwitch = () => {
    updateMapFilters('');
  };
  const onCloseEditMode = () => {
    setIsEditing(false);
    //Set title to the default value
    setTitle('Test title');
  };

  return (
    <StyledExtendedHeader component="header">
      <Box>
        <Box sx={headerTop}>
          <ArrowBackBox onClick={() => {}} />
          {isEditing ? (
            <EditTitleBox
              value={title}
              onChangeHandler={onChangeHandler}
              iconSize={iconSize}
              greenColor={greenColor}
              onCloseEditMode={onCloseEditMode}
              toggleEditHandler={toggleEditHandler}
            />
          ) : (
            <DisplayTitleBox
              iconSize={iconSize}
              greyColor={greyColor}
              title={title}
              toggleEditHandler={toggleEditHandler}
            />
          )}
        </Box>
        <Box sx={extendedNavList}>
          {extendedNavItems?.map(({ title, url, icon }) => (
            <ExtendedNavItem
              key={title}
              title={title}
              url={url}
              icon={icon}
              greyColor={greyColor}
              activeItem={activeItem}
              onSetActiveItemHandler={onSetActiveItemHandler}
            />
          ))}
        </Box>
      </Box>
      <Box sx={rightSide}>
        <Box>
          {showButton && isMobile ? (
            <IconButton to={TENANT_SWITCH_PATH} component={Link} onClick={handleAccountSwitch}>
              <ListIcon width={24} height={24} />
            </IconButton>
          ) : (
            <SwitchAccountBtn handleAccountSwitch={handleAccountSwitch} />
          )}
        </Box>
        <UserPopoverBtn isTransparent={isTransparent} />
      </Box>
    </StyledExtendedHeader>
  );
};
